import React from "react";
import g from "glamorous";
import Link from "gatsby-link";
import Layout from "../components/layout";
import "../../index.css";
import { graphql } from "gatsby";

import {
  Container,
  LiveWrapper,
  StyledEditor,
  StyledError,
  StyledPreview,
  StyledProvider,
} from "./index";
import { scope } from "glamor";
import SEO from "../components/SEO";

const editorCode = (data) => `() => (
      <>
          <h3>Blog</h3>
          count: ${data.allMarkdownRemark.totalCount} 🍰 
          
          (edit me)
      </>
    )
`;
export default ({ data }) => {
  return (
    <Layout>
      <SEO frontmatter={data.allMarkdownRemark.edges[0].node} isBlogPost />
      <div className="mb-8">
        <h2 className="heading mt-10 mb-6">Notes</h2>
        <div className="flex flex-col">
          <span>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div
                className="flex mb-4 justify-between items-end"
                key={node.id}
              >
                <Link
                  className="flex mb-4 justify-between items-end"
                  to={`${node.fields.slug}`}
                >
                  <div className="flex-1 mr-4 flex">
                    <h3 className="text-sm md:text-base hover:bg-black hover:text-white">
                      {node.frontmatter.title}
                    </h3>
                  </div>
                  <div className="text-xs text-gray-400">
                    {node.frontmatter.date}
                  </div>
                </Link>
              </div>
            ))}
          </span>
        </div>
        {/*</article>*/}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NotesLayoutNotesQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            category
            description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
